<template>
	<div class="">
		<header class="header">
			<i class="title-icon iconfont" :class="type==1?'icon-yishiguanli':'icon-yaoshiguanli'"></i>
			<h5 style="font-size: 16px;">{{ type == 1 ? "医师管理" : "药师管理" }}</h5>
		</header>
		<div class="box-container">
			<div class="gg-container" style="padding: 10px 30px; box-sizing: border-box;position: relative;">
				<div style="margin-bottom: 10px;">
					<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
						@select="handleSelect">
						<el-menu-item index="1">基础信息</el-menu-item>
						<!-- <el-menu-item index="2">证件材料</el-menu-item> -->
						<el-menu-item v-if="type == 1" index="3">就诊设置</el-menu-item>
						<!-- <el-menu-item index="4">扩展信息</el-menu-item> -->
					</el-menu>
					
					
				</div>
				<div class="container-box">
					<el-form label-position="left" ref="ruleForm" :model="form" label-width="150px" :rules="rules"
						v-loading="formLoading" class="demo-ruleForm">
						<div v-show="activeIndex == 1">
							<div class="nav-title">基础信息</div>
							<el-row>
								<el-col :span="5" :row="2">
									<el-form-item :label="(type==1?'医生':'药师')+'头像'" prop="head_pic">
										<customUpload @on-success="(file) => onSuccess(file, 'head_pic')">
										</customUpload>
									</el-form-item>
								</el-col>
								<el-col :span="7" :row="2">
									<div style="color: #a399a6; font-size: 14px">
										<p>图片建议上传146(宽)*146(高)，近6个月医师半身照</p>
										<p>(上传图片大小不要超过2M)</p>
									</div>
								</el-col>
								
								
							</el-row>
							<el-row>
								<el-col :span="12">
									<el-form-item label="姓名" prop="doctor_name">
										<el-input v-model="form.doctor_name" style="width: 70%"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="性别" prop="ge_code">
										<el-radio v-for="item in genderOptions" :key="item.value"
											v-model="form.ge_code" :label="item.value">{{ item.label }}</el-radio>
									</el-form-item>
								</el-col>				
							</el-row>
							<el-row>
								<el-col :span="12">
									<el-form-item label="手机号" prop="doc_tel">
										<el-input v-model="form.doc_tel" style="width: 70%"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="身份证号" prop="id_card">
										<el-input v-model="form.id_card" style="width: 70%"></el-input>
									</el-form-item>
								</el-col>
								
							</el-row>
							<el-row>
								
								<el-col :span="12" v-if="type == 1">
									<el-form-item label="第一执业机构" prop="organization_name">
										<el-input v-model="form.organization_name" style="width: 70%"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="职称" prop="title_code">
										<el-select v-model="form.title_code" placeholder="请选择" style="width: 70%">
											<el-option v-for="item in titleOptions" :key="item.value"
												:label="item.label" :value="item.value">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
								
							</el-row>
				
							<el-row>
								<el-col :span="12">
									<el-form-item label="执业起止日期" prop="doc_multi_sited_date">
										<el-date-picker style="width: 70%" v-model="form.doc_multi_sited_date" type="daterange"
										range-separator="至"
										start-placeholder="开始日期"
										end-placeholder="结束日期"
											value-format="yyyy-MM-dd">
										</el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="省市区">
										<el-select v-model="form.province_code" placeholder="请选择省"
											style="width: 22%; margin-right: 12px" @change="changeProvince">
											<el-option v-for="item in address" :key="item.id" :label="item.title"
												:value="item.id">
											</el-option>
										</el-select>
										<el-select v-model="form.city_code" placeholder="请选择市"
											style="width: 22%; margin-right: 12px" @change="changeCity">
											<el-option v-for="item in citys" :key="item.id" :label="item.title"
												:value="item.id">
											</el-option>
										</el-select>
										<el-select v-model="form.district_code" placeholder="请选择区" style="width: 22%">
											<el-option v-for="item in areas" :key="item.id" :label="item.title"
												:value="item.id">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
							
							</el-row>
							<el-row>
								<el-col :span="12">
									<el-form-item label="执业证号" prop="prac_no">
										<el-input v-model="form.prac_no" style="width: 70%"></el-input>
									</el-form-item>
								</el-col>

								<el-col :span="12">
									<el-form-item label="门诊机构" prop="prac_no">
										<el-select v-model="form.agent_code" placeholder="请选择"
											style="width: 70%; margin-right: 12px">
											<el-option v-for="item in agentOpts" :key="item.agent_code" :label="item.agent_name"
												:value="item.agent_code">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
								
							</el-row>

							<el-row v-if="type ==2">
								<!-- <el-col :span="12">
									<el-form-item label="个人简介">
										<el-input type="textarea" :row="3" v-model="form.doc_comment" style="width: 70%"></el-input>
									</el-form-item>
								</el-col> -->
								
							</el-row>

							<!-- <div class="nav-title">证件材料</div>
							<el-row>
								<el-col :span="12">
									<div class="nav-title">合同</div>
									<div class="pic-item">
										<customUpload width="211px" height="102px" dw="45px" dh="45px" bgColor="#E5E5E5"
											bd="none" title="合同" icon="icon-yiyuan" style="margin-right: 70px"
											@on-success="
                      (file) => onSuccess(file, 'employ_files_list', 'a')
                    "></customUpload>
										<customUpload width="211px" height="102px" dw="45px" dh="45px" bgColor="#E5E5E5"
											bd="none" title="合同" icon="icon-yiyuan" style="margin-right: 70px"
											@on-success="
                      (file) => onSuccess(file, 'employ_files_list', 'b')
                    "></customUpload>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="nav-title">医师执业注册申请审核表</div>
									<div class="pic-item">
										<customUpload width="211px" height="102px" dw="45px" dh="45px" bgColor="#E5E5E5"
											bd="none" title="审核表" icon="icon-yiyuan" style="margin-right: 70px"
											@on-success="
                      (file) => onSuccess(file, 'prac_apply')
                    "></customUpload>
									
									</div>
								</el-col>
							</el-row> -->
							
						</div>


						<div v-if="activeIndex == 3 && type == 1">
							<div class="nav-title">擅长与简介</div>
							<el-form-item label="科室" prop="depart_id">
								<el-cascader style="width: 92%" v-model="form.depart_id" :options="departDic"
									:props="{ checkStrictly: true }" @change="changeDepart" clearable></el-cascader>
							</el-form-item>

							<el-form-item label="擅长疾病" prop="symptom">
								<el-select v-model="form.symptom" filterable multiple placeholder="请选择"
									style="width: 92%">
									<el-option v-for="item in symptom" :key="item.id" :label="item.symptom_name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="个人简介" prop="doc_comment">
								<el-input type="textarea" :row="5" v-model="form.doc_comment" style="width: 92%">
								</el-input>
							</el-form-item>

							<el-form-item label="义诊" prop="free_status">
								<el-switch style="margin-left: 6px" class="switch" v-model="form.free_status"
									:active-value="1" :inactive-value="0" active-text="开" inactive-text="关">
								</el-switch>
							</el-form-item>
							<el-form-item label="图文问诊设置" prop="pic_status">
								<el-switch style="margin-left: 6px" class="switch" v-model="form.pic_status"
									:active-value="1" :inactive-value="0" active-text="开"
									@change="(e) => changeStatus(e, 'pic')" inactive-text="关">
								</el-switch>
							</el-form-item>
							<el-row>
								<el-col :span="8">
									<el-form-item label="复诊价格(单位:￥)" prop="pic_price">
										<el-input :disabled="form.pic_status == 0" v-model="form.pic_price"
											style="width: 70%"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="接诊上限" prop="pic_num">
										<el-input :disabled="form.pic_status == 0" v-model="form.pic_num"
											style="width: 70%"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="接诊时长" prop="pic_time">
										<el-input :disabled="form.pic_status == 0" v-model="form.pic_time"
											style="width: 40%; margin-right: 15px"></el-input>
										<el-select :disabled="form.pic_status == 0" v-model="form.pic_time_type"
											clearable placeholder="请选择" style="width: 30%">
											<el-option v-for="item in timeOptions" :key="item.value" :label="item.label"
												:value="item.value">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-row>

							<el-form-item label="视频问诊设置" prop="video_status">
								<el-switch style="margin-left: 6px" class="switch" v-model="form.video_status"
									:active-value="1" @change="(e) => changeStatus(e, 'video')" :inactive-value="0"
									active-text="开" inactive-text="关">
								</el-switch>
							</el-form-item>
							<el-row>
								<el-col :span="8">
									<el-form-item label="复诊价格(单位:￥)" prop="video_price">
										<el-input :disabled="form.video_status == 0" v-model="form.video_price"
											style="width: 70%"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="接诊上限" prop="video_num">
										<el-input :disabled="form.video_status == 0" v-model="form.video_num"
											style="width: 70%"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<div class="pic-item">
										<el-form-item label="接诊时长" prop="video_time">
											<el-input :disabled="form.video_status == 0" v-model="form.video_time"
												style="width: 40%; margin-right: 15px"></el-input>
											<el-select :disabled="form.video_status == 0" v-model="form.video_time_type"
												clearable placeholder="请选择" style="width: 30%">
												<el-option v-for="item in timeOptions" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
						</div>
			
					</el-form>
				</div>

				<span slot="footer" class="dialog-footer">
					<!-- <el-button type="info" size="small" @click="handleCancel()">取消</el-button> -->
					<el-button type="success" size="small" @click="saveForm()">保存</el-button>
					<!-- <el-button type="success" size="small" @click="saveForm1111()">保存草稿箱</el-button>
					<el-button type="success" size="small" @click="restore()">还原草稿箱</el-button> -->
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getOrganList,
		addOrgan,
		updateOrgan,
		delOrgan,
	} from "@/api/audit/doctor";
	import {
		getHospital,
		getAddress,
		addDoctor
	} from "@/api/basic";
	import { getAdminCommonMerchants } from "@/api/finance/index.js";
	import {
		mapState
	} from "vuex";
	import globalData from "@/utils/globalData.js";
	import customUpload from "@/components/customUpload";
	export default {
		name: "addDoctor",
		components: {
			customUpload,
		},
		mounted() {
			this.$store.dispatch("dictionary/getDicData", [{
				stateName: "departDic",
				api: "/admin/hospital/getDeparts",
			}, ]);
			this.$store.dispatch("dictionary/getDicData", [{
				stateName: "doctorDic",
				api: "/admin/hospital/getDicts",
			}, ]);
			this.$store.dispatch("dictionary/getDicData", [{
				stateName: "departSymptom",
				api: "/admin/setting/getDepartSymptomSelect",
			}, ]);
		},
		computed: {
			...mapState({
				sexDict: (state) => state.dictionary.doctorDic.gender,
				departDic: (state) => state.dictionary.departDic,
				doctorDic: (state) => state.dictionary.doctorDic, //字典
				departSymptom: (state) => state.dictionary.departSymptom, //字典
			}),
		},

		created() {
			let type = this.$route.query.type || 1;
			this.name = this.$route.query.name || 'doctor';
			this.type = type;
			if (type == 1) {
				this.rules = {
					...this.rules,
					...this.doctorRules
				}
			}

			this.getAddress();
			this._getAdminCommonMerchants()
		},
		data() {
			return {
				agentOpts:[],
				genderOptions:globalData.genderOptions,
				titleOptions:globalData.titleOptions,
				workStatus: 'add',
				punishStatus: 'add',
				name: 'doctor',
				punishIndex: -1,
				workIndex: -1,
				type: 1,
				formLoading: false,
				work: {},
				punish: {
					content: ''
				},
				dialogWorkVisible: false,
				dialogPunishVisible: false,
				symptom: [],
				citys: [],
				areas: [],
				loading: false,
				hospitalOptions: [],
				activeIndex: "1",
				address: [],
				timeOptions: [{
						label: "分",
						value: 0
					},
					{
						label: "时",
						value: 1
					},
					{
						label: "天",
						value: 2
					},
				],
				dialogTitle: "",
				dialogType: "",
				datetimerange: [],
				currUser: {},
				dialogTableVisible: false,
				searchParams: {},
				total: 0,
				listLoading: false,
				fullscreenLoading: false,
				listQuery: {
					page: 1,
					limit: 10,
					importance: undefined,
					title: undefined,
					type: undefined,
					sort: "+id",
				},
				tableData: [],
				options: [],
				ruleForm: {
					examine: "",
				},
				typeOptions: [{
					label: "未定级",
					value: 1
				}],
				natureOptions: [{
					label: "民营",
					value: 1
				}],
				form: {
					province_code: "",
					video_time_type: 0,
					pic_time_type: 0,
					punish_content: [],
					prac_scope_approval: [],
					symptom: [],
					work_experience_list: [],
					agent_code:""
				},
				workRules: {
					company: [{
						required: true,
						message: "请填写就职单位",
						trigger: "blur"
					}, ],
					duration: [{
						required: true,
						message: "请选择就职时间",
						trigger: "change"
					}, ],
					depart_id: [{
						required: true,
						message: "请选择就职科室",
						trigger: "change"
					}, ],
					title_id: [{
						required: true,
						message: "请选择职称",
						trigger: "change"
					}, ],
					authenticator: [{
						required: true,
						message: "请填写证明人",
						trigger: "blur"
					}, ],
				},
				punishRules: {
					content: [{
						required: true,
						message: "请填写就职单位",
						trigger: "blur"
					}, ],
				},
				doctorRules: {
					depart_id: [{
						required: true,
						message: "请选择科室",
						trigger: "change"
					}, ],
					symptom: [{
						required: true,
						message: "请选择擅长疾病",
						trigger: "change"
					}, ],
					free_status: [{
						required: true,
						message: "请选择义诊状态",
						trigger: "change"
					}, ],
					pic_status: [{
						required: true,
						message: "请选择图文问诊状态",
						trigger: "change"
					}, ],
					video_status: [{
						required: true,
						message: "请选择视频问诊状态",
						trigger: "change"
					}, ],
				},
				rules: {
					head_pic: [{
						required: true,
						message: "请上传头像",
						trigger: "change"
					}, ],
					ge_code: [{
						required: true,
						message: "请选择性别",
						trigger: "change"
					}],
					doctor_name: [{
						required: true,
						message: "请填写姓名",
						trigger: "blur"
					}, ],
					work_inst_code: [{
						required: true,
						message: "请选择第一执业机构",
						trigger: "change"
					}, ],
					id_expire_date: [{
						required: true,
						message: "请选择证件有效期",
						trigger: "change"
					}, ],
					doc_tel: [{
						required: true,
						message: "请填写手机号",
						trigger: "blur"
					}],
					doc_edu: [{
						required: true,
						message: "请选择学历",
						trigger: "change"
					}],
					id_card: [{
						required: true,
						message: "请填写身份证号"
					}],
					organization_name: [{
						required: true,
						message: "请填写第一执业机构"
					}],
			
					district_code: [{
						required: true,
						message: "请选择省市区",
						trigger: "change"
					}, ],
			
					prac_no: [{
						required: true,
						message: "请填写执业证号",
						trigger: "blur"
					}, ],
					
					doc_multi_sited_date: [{
						required: true,
						message: "请选择执业起止日期",
						trigger: "change",
					}, ],
				
					title_code: [{
						required: true,
						message: "请选择职称",
						trigger: "change"
					}, ],

					title_rec_date: [{
						required: true,
						message: "请选择职称证发证日期",
						trigger: "change",
					}, ],
					title_no: [{
						required: true,
						message: "请填写职称证号"
					}],

					doc_comment: [{
						required: true,
						message: "请填写个人简介"
					}],

					work_experience_list: [{
						required: true,
						message: "请填写工作经历",
						trigger: "change"
					}, ],
				},
			};
		},

		methods: {
			_getAdminCommonMerchants() {
				getAdminCommonMerchants().then((res) => {
					if (res.code == 200) {
					let infoList = res.data.map((item) => {
						return {
						agent_id: item.id,
						agent_code: item.agent_code,
						agent_name: item.merchant_name,
						};
					});
					this.agentOpts = infoList;
	
					}
				});
			},
			handleCancel() {
				console.log('cancel', this.name);
				this.$router.go(-1);
			},
			handleWork(index) {
				this.workIndex = index;
			},

			handleEditWork(row, index) {
				this.workStatus = 'edit'
				this.workIndex = -1;
				this.work = row;
				this.$set(this.work, 'index', index)
				this.dialogWorkVisible = true;

			},
			handleDelWork(index) {
				this.workIndex = -1;
				this.form.work_experience_list.splice(index, 1);
			},

			handleEditPunish(row, index) {
				this.punishStatus = 'edit'
				this.punishIndex = -1;
				this.punish.content = row;
				this.$set(this.punish, 'index', index)
				this.dialogPunishVisible = true;

			},
			handleDelPunish(index) {
				this.punishIndex = -1;
				this.form.punish_content.splice(index, 1);
			},
			handlePunish(index) {
				this.punishIndex = index;
			},

			restore() {
				let form = localStorage.getItem("doc_form")
				if (form) {
					this.form = JSON.parse(form);
				}

			},
			saveForm1111() {
				sessionStorage.setItem("doc_form", JSON.stringify(this.form));
				localStorage.setItem("doc_form", JSON.stringify(this.form));
				this.$message.success("草稿箱保存成功！");
			},
			saveForm() {
				this.formLoading = true;
				this.$refs["ruleForm"].validate((valid, obj) => {
					console.log(valid, obj)
					console.log(111, obj, Object.values(obj))
					if (valid) {
						if (this.form.doctor_name.length < 2) {
							this.$message({
								message: '姓名必须为两字以上汉字',
								type: 'error'
							})
							this.formLoading = false;
							return
						}
						this.form.type = this.type;
						addDoctor(this.form).then((res) => {
							this.formLoading = false;
							if (res.code == 200) {
								this.$message.success("添加成功！");
								this.from = {}
								setTimeout(() => {
									if(this.$store.state.user.level == 0){
										//返回上一页
										this.$router.go(-1);
									}else{
										this.$router.push({
											name: 'proxyDoc'
										})
									}
									
								}, 300)
							} else {
								return this.$message.error(res.msg ? res.msg : res.message);
							}
						});
					} else {
						this.$message.error(Object.values(obj)[0][0]);
					}
					this.formLoading = false;
				});
			},
			changeStatus(e, type) {
				var msg = type == "pic" ? "图文问诊" : "视频问诊";
				if (e == 1) {
					this.$set(this.rules, type + "_price", [{
						required: true,
						message: `请选择${msg}价格`
					}, ]);
					this.$set(this.rules, type + "_num", [{
						required: true,
						message: `请选择${msg}接单上限`
					}, ]);
					this.$set(this.rules, type + "_time", [{
						required: true,
						message: `请选择${msg}接诊时长`
					}, ]);
					this.$refs["ruleForm"].validateField([
						type + "_price",
						type + "_num",
						type + "_time",
					]);
				} else {
					this.$refs["ruleForm"].clearValidate([
						type + "_price",
						type + "_num",
						type + "_time",
					]);
				}
			},
			addWork() {
				this.$refs["workForm"].validate((valid) => {
					if (valid) {
						if (this.work.depart_id[1]) {
							this.work.department_id = this.work.depart_id[1];
						} else {
							this.work.department_id = this.work.depart_id[0];
						}
						let departName =
							this.departSymptom.departs.find(
								(v) => v.id == this.work.department_id
							).depart_name || "";
						let titleName =
							this.doctorDic.doctor_title.find(
								(v) => v.value == this.work.title_id
							).label || "";
						this.$set(this.work, "depart_name", departName);
						this.$set(this.work, "title_name", titleName);
						if (this.workStatus == 'add') {
							this.form.work_experience_list.push(this.work);
						} else {
							this.form.work_experience_list[this.work.index] = this.work
						}

						this.dialogWorkVisible = false;
					}
				});
			},
			addPunish() {
				this.$refs["punishForm"].validate((valid) => {
					if (valid) {
						if (this.punishStatus == 'add') {
							this.form.punish_content.push(this.punish.content);
						} else {
							this.form.punish_content[this.punish.index] = this.punish.content;

							console.log(this.punish.index, this.punish.content)
						}

						this.dialogPunishVisible = false;
					}
				});
			},
			handleAddWork() {
				this.work = {};
				this.workStatus = 'add'
				this.dialogWorkVisible = true;
			},
			handlePunis() {
				this.punish = {};
				this.punishStatus = 'add'
				this.dialogPunishVisible = true;
			},

			changeDepart(e) {
				if (e[1]) {
					this.form.depart_id = e[1];
				} else {
					this.form.depart_id = e[0];
				}
				let curSymptom = this.departSymptom.departs.find((v) => v.id == this.form.depart_id);
				this.symptom = curSymptom ? curSymptom.symptom : [];
				this.form.symptom = []
			},
			changeProvince(e) {
				let curProvince = this.address.find((v) => v.id == e);
				this.citys = curProvince ? curProvince.children : [];
				this.areas = [];
				this.$set(this.form, "city_code", null);
				this.$set(this.form, "district_code", null);
			},
			changeCity(e) {
				let curCity = this.citys.find((v) => v.id == e);
				this.areas = curCity ? curCity.children : [];
				this.$set(this.form, "district_code", null);
			},
			getAddress(id) {
				getAddress(id).then((res) => {
					if (res.code == 200) {
						this.address = res.data;
					}
				});
			},
			remoteMethod(query) {
				if (query !== "") {
					console.log(11111111, query);
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						getHospital(query).then((res) => {
							if (res.code == 200) {
								this.hospitalOptions = res.data;
							}
						});
						// this.options = this.list.filter((item) => {
						//   return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
						// });
					}, 200);
				} else {
					this.hospitalOptions = [];
				}
			},
			onSuccess(file, type, name) {
				let data = this.form[type];
				if(!type){
					console.log(name,2222)
					this.form[name] = file;
					this.$set(this.form, name, file);
				}else{
					if (name) {
						if (!data) {
							data = {};
						}
						data[name] = file;
						this.$set(this.form, type, data);
					} else {
						let data = file;
						this.$set(this.form, type, data);
					}
				}

				console.log(file, type, name,this.form)
			},
			handleSelect(e) {
				this.activeIndex = e;
			},

			delItem(row) {
				this.$confirm("确认删除该机构?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						delOrgan(row.sign_id).then((res) => {
							if (res.code == 200) {
								this.getList();
								this.$message({
									type: "success",
									message: "删除成功!",
								});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			addForm() {
				addOrgan(this.form)
					.then((res) => {
						if (res.code == 200) {
							this.getList();
							this.$message.success("添加成功！");
							this.dialogTableVisible = false;
						} else {
							return this.$message.error(res.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			updateForm() {
				updateOrgan(this.form).then((res) => {
					if (res.code == 200) {
						this.getList();
						this.$message.success("修改成功！");
						this.dialogTableVisible = false;
					} else {
						return this.$message.error(res.msg);
					}
				});
			},
			getList() {
				this.listLoading = true;
				this.fullscreenLoading = true;
				let params = {
					...this.searchParams,
				};
				params.page = this.listQuery.page;
				params.type = 1;
				params.page_size = this.listQuery.limit;

				getOrganList(params)
					.then((response) => {
						let data = response;
						if (data.code == 200) {
							let _data = data.data;
							this.total = _data.total;
							this.listQuery.limit = Number(_data.per_page);
							this.tableData = _data.list;
						}
						this.fullscreenLoading = false;
						this.listLoading = false;
					})
					.catch((error) => {
						this.$message.error(error.message);
						this.listLoading = false;
						this.fullscreenLoading = false;
					});
			},
			reset() {
				// this.searchParams = {};
				this.listQuery.page = 1;
				this.getList()
			},

			dateChange(val) {
				if (val && val.length) {
					this.searchParams.created_at = JSON.stringify(val);
				} else {
					this.searchParams.created_at = [];
				}
				this.getList();
			},
			editOrgan(type, row) {
				if (type == "add") {
					this.form = {};
					this.dialogTitle = "新增机构";
				} else {
					this.dialogTitle = "修改机构";
					this.form = {
						...row
					};
				}

				this.dialogType = type;
				this.dialogTableVisible = true;
			},
			editSuccess() {
				alert("编辑成功");
				this.dialogTableVisible = false;
				this.getList();
			},
		},
		filters: {
			controllshow(str, num) {
				//如果当前字符串小于nunm，返回原值
				if (str) {
					if (str.length <= num) {
						return str;
					}
					if (str.length > num) {
						return str.substr(0, num - 1) + "...";
					}
				} else {
					return str;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.header {
		display: flex;
		align-items: center;
		height: 26px;
		margin-bottom: 10px;

		i {
			width: 25px;
			height: 25px;
			background-color: #2632fb;
			color: white;
			line-height: 25px;
			text-align: center;
			border-radius: 4px;
			margin-right: 8px;
		}
	}

	.nav-title {
		position: relative;
		font-size: 14px;
		font-weight: bold;
		padding-left: 5px;
		margin: 20px 0;
	}

	.work-required {
		display: flex;
		margin-top: 5px;
		align-items: center;
		margin-right: 3px;
		color: #ff4949;

		font-size: 14px;
	}

	.nav-title:before {
		position: absolute;
		width: 2px;
		height: 80%;
		background: #2362fb;
		left: 0px;
		top: 2px;
		content: "";
	}

	.pic-item {
		display: flex;
	}

	/deep/.switch .el-switch__label {
		position: absolute;
		display: none;
		color: #fff !important;
	}

	/*打开时文字位置设置*/
	/deep/.switch .el-switch__label--right {
		z-index: 1;
	}

	/* 调整打开时文字的显示位子 */
	/deep/.switch .el-switch__label--right span {
		margin-left: -5px;
		// margin-right: 40px;
	}

	/*关闭时文字位置设置*/
	/deep/.switch .el-switch__label--left {
		z-index: 1;
	}

	/* 调整关闭时文字的显示位子 */
	/deep/.switch .el-switch__label--left span {
		margin-left: 30px;
	}

	/*显示文字*/
	/deep/.switch .el-switch__label.is-active {
		display: block;
	}

	/* 调整按钮的宽度 */
	/deep/.switch.el-switch .el-switch__core,
	.el-switch .el-switch__label {
		width: 50px !important;
		margin: 0;
	}

	.dialog-footer {
		position: absolute;
		right:20px;
		top:-7px;
		margin-top: 30px;
		display: flex;
		justify-content: center;
		width: 100px;
	}

	.add-item {
		border: 1px solid #f3f3f3;
		color: #8a8d8f;
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 14px;
		cursor: pointer;
	}

	.form-box {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin-bottom: 15px;

		.form-label {
			width: 130px;
			text-align: left;
			color: #91918f;
		}

		.form-label {
			text-align: left;
			color: #484848;
		}
	}

	.error-tips {
		color: red;
		font-size: 12px;
		margin: -top5px;
	}

	.popver {
		position: absolute;
		right: 0;
		width: 35px;
		height: 23px;
		top: 0;
		cursor: pointer;
	}

	.popver:before {
		width: 35px;
		height: 23px;
		display: inline-block;
		text-align: center;
		line-height: 15px;
		border: 1px solid #f1f1f1;
		border-radius: 3px;
		content: "...";
	}

	.pop-hander {
		padding-top: 7px;
		position: absolute;
		right: -15px;
		top: 5px;
		display: flex;
		width: 60px;
		font-size: 13px;
		flex-direction: column;
		border: 1px solid #f1f1f1;

		div {
			width: 60px;
			height: 30px;
			text-align: center;
			line-height: 30px;
		}

		div:hover {
			color: #7297fd;
			background: #e9efff;
		}
	}

	.el-popover {
		min-width: none !important;
	}

	.container-box {
		overflow: hidden;
		overflow-y: auto;
		// height: calc(100vh - 360px);
	}
</style>
